window.onerror = function (msg, url, lineNo, columnNo, error) {
    var string = msg.toLowerCase();
    var substring = "script error";
    if (string.indexOf(substring) > -1){
        return false;
    }
    if(!window.gnify_rum) {
        return false;
    }

    var xmlhttp = new XMLHttpRequest();
    xmlhttp.open('POST', window.gnify_rum.url);
    xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xmlhttp.send(JSON.stringify({
        message: msg + ' Source: ' + url + ' Line: ' + lineNo + ' Column: ' + columnNo,
        stack: error.stack || '',
        url: window.location.href
    }));

    return false;
}
